
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1500px) {
.imgs {
    width: 79px;
}
.horizontal_green_pipes4_line{
  width: 41px;
}
.vertical_green_pipe{
  height: 660px;
}
.vertical_orange_pipe{
  margin-top:8%;
   height: 675px;
}
.horizontal_orange_pipe{
 
  margin-left: -1.3%;
}

.horizontal_green_pipes4_line{
  width: 44.5px;
}
}

@media (max-width: 1480px) {
  .imgs {
      width: 75px;
  }
  .horizontal_green_pipes4_line{
    width: 42px;
  }
  .vertical_green_pipe{
    height: 660px;
  }
  .vertical_orange_pipe{
    margin-top:8%;
     height: 675px;
  }
  .horizontal_orange_pipe{
   
    margin-left: -1.3%;
  }
  .horizontal_green_pipe4_line{
    width: 94px;
  }
  .horizontal_green_pipes4_line{
    width: 42px;
  }
  }
@media (max-width: 1450px) {
  .imgs {
    width: 73px;
  }

  .vertical_orange_pipe{
   margin-top:8.1%;
   height: 670px;
  }

  .vertical_green_pipe{
    height: 657px;
  }
  
  .horizontal_green_pipe4_line{
    width: 82px;
  }
  .horizontal_orange_pipe{
    margin-left: -0.7%;
  }
  .horizontal_green_pipes4_line{
    width: 41px;
  }
 
}

@media (max-width: 1400px) {
  
  .horizontal_green_pipes4_line{
    width: 40px;
  }
  .pipes4{
    width: 5%;
  }
  .imgs {
    width: 70px;
  }

  .vertical_orange_pipe{
   margin-top:8.6%;
   height: 659px;
  }

  .horizontal_orange_pipe{
    margin-left: -0.7%;
  }

  .horizontal_green_pipe4_line{
    width: 67px;
  }
 .vertical_green_pipe2_line{
    width: 0.5rem;
    margin-top:7.8%;
   }
   .vertical_green_pipe{
    height: 645px;
  }
}

@media (max-width: 1370px) {
  .imgs {
    width: 67px;
  }
  
  .horizontal_orange_pipe{
    margin-left: -0.1%;
  }
  .vertical_orange_pipe{
    margin-top:8.6%;
    height: 658px;
   }
}

@media (max-width: 1350px) {
  .vertical_green_pipe2_line{
    width: 0.6rem;
    margin-top:8.2%;
   }
   .imgs {
    width: 65px;
  }
  .vertical_orange_pipe{
    margin-top:9%;
    height: 658px;
   }
}

@media (max-width: 1330px) {
  .imgs {
    width: 60px;
  }

  .vertical_orange_pipe{
    margin-top:9.1%;
    height: 658px;
   }
   .horizontal_green_pipe4_line{
    width: 55px;
  }
  .vertical_green_pipe{
    height: 645px;
  }
  .horizontal_green_pipes4_line{
    width: 43px;
  }
}

@media (max-width: 1280px) {
  .imgs4{
    
  }
  .horizontal_green_pipes4_line{
    width: 40px;
  }
  .imgs {
    width: 58px;
  }
  .vertical_green_pipe2_line{
   
    margin-top:8.4%;
   }
  .horizontal_orange_pipe{
    margin-left: 0.4%;
  }
  .vertical_orange_pipe{
    margin-top:9.4%;
    height: 655px;
   }
   .vertical_green_pipe{
    height: 640px;
  }
   .horizontal_green_pipe4_line{
    width: 50px;
  }
}


@media (max-width: 1260px) {
  .imgs {
    width: 55px;
  }
  .horizontal_green_pipe4_line{
    width: 63px;
  }
  .vertical_green_pipe2_line{
    margin-top:8.6%;
    width: 0.7rem;
   }
}

@media (max-width: 1230px) {
  .imgs {
    width: 50px;
  }
  .vertical_green_pipe2_line{
    margin-top:8.8%;
    width: 0.7rem;
   }
  .horizontal_green_pipe4_line{
    width: 63px;
  }
  .container{
    margin-left: 1.5rem;
  }
  .horizontal_orange_pipe{
    margin-left: -1.8%;
  }
  .horizontal_green_pipe4_line{
    width: 50px;
  }
  .vertical_orange_pipe{
    height: 647px;
    margin-top:9.9%;
   }
}

/* 
.imgs4{
  width: 6%;
}

.imgs4 img{
  width: 100%;
} */